import {es} from 'date-fns/locale';

import {ROUTES_ES} from '../routes/es';
import {TIMEZONE_NAME} from '../timezone/spain';
import {GameId} from '../../app/games/data/game-id';
import {SearchLotteryType} from '../../app/games/game-metadata/data/search-lottery-type';

export const ENVIRONMENT_ES = {
  id: 'es',
  fulldomain: 'web.tulotero.es',
  locale: {
    domain: '.es',
    locale: 'es-ES',
    dateLocale: es,
    dateFormats: {
      format: 'dd/MM/YYYY',
      shortDayMonthYear: 'dd/MM/yy',
      dayMonthNameYear: 'd MMM, y',
      dayMonth: 'd MMM.',
      date: 'P',
      dateShort: 'dd MMM. - HH:mm',
      dayNameDate: 'eeee, P',
      dayNameMonthDate: 'eee, d MMM',
      dayMonthTime: 'd MMM., HH:mm',
      shortMonth: 'MMM',
      time: 'HH:mm',
      shortDateAndTime: 'dd/MM/yyyy HH:mm',
      dayName: 'eee',
    },
    zipCodeFormat: '00000',
    currencyCode: 'EUR',
    timezone: TIMEZONE_NAME,
    partnersLogo: 'assets/img/logos/logos-confianza.svg',
    sponsorLogo: 'assets/img/menu/embajador-EURO.svg',
    user: {
      defaultCountry: 'es',
      defaultPhoneCode: 'es',
      allowDNI: true,
      validateDni: true,
    },
    bankAccountType: 'IBAN',
    bankAccountFieldLengths: [4, 4, 4, 4, 4, 4],
    routes: ROUTES_ES,
  },
  app: {
    // TODO real links
    ios: {
      id: '948041023',
      link: 'https://itunes.apple.com/ES/app/id948041023?pid=pwa_menu',
    },
    android: {
      link: 'https://tulotero.es/descarga-android',
    },
  },
  fingerprint: {
    region: 'eu',
    endpoint: 'https://fp.web.tulotero.es',
  },
  kyc: {
    allowSkip: true,
    methods: {
      default: 'dni',
      voluntary: true,
    },
    dateShowChristmasText: {
      start: 22, //DD of December
      end: 11, //DD of January
    },
  },
  selfExclusion: {
    showInfo: true,
  },
  money: {
    fraudSystem: false,
    pci: false,
    loadMin: 0.01,
    bizum: {
      enable: true,
      amountMax: 100000,
    },
    // TODO move to paymentMethods when credit card is migrated
    defaultPageDesktop: 'CARD',
  },
  selfControl: {
    removeLimits: true,
  },
  menu: {
    background: 'assets/img/mobile/menu/background.jpg',
    backgroundChristmas: 'assets/img/mobile/menu/background-christmas.jpg',
    lastAccess: true,
    showClock: true,
  },
  showDesktopFooter: true,
  profile: {
    background: 'assets/img/mobile/perfil/background.jpg',
    backgroundChristmas: 'assets/img/mobile/perfil/background-christmas.jpg',
    hiddenRequiredData: false,
    autocompleteState: true,
  },
  init: {
    screenLogo: 'assets/img/mobile/screen-android-es-es.png',
  },
  balance: {
    showKycBanner: true,
  },
  games: {
    logos: {
      rounded: true,
    },
    play: {
      savedPlayTimeout: 900,
    },
    results: {showCheckBanner: true},
    lottery: {
      autheticatedImageTickets: true,
      showManualLotteryResultInfo: true,
      fractionLabelType: 'tickets',
      showLotteryManualAmount: true,
      showBooths: true,
      allowSelectBoothId: [GameId.LOTERIA_NACIONAL],
      searchLotteryType: SearchLotteryType.SELAE,
    },
  },
  scanner: {
    gamesMobile: [GameId.LOTERIA_NACIONAL, GameId.EURODREAMS],
    gamesDesktop: [GameId.LOTERIA_NACIONAL],
    gamesManual: [GameId.LOTERIA_NACIONAL],
  },
  groups: {
    enable: true,
    showInfoPrizes: false,
    shareUrl: 'https://tulotero.es/g/',
  },
  preferences: {
    notificationCategory: 'notificaciones',
  },
  registration: {smsTimeout: {desktop: 60, mobile: 60}},
  social: {
    sponsorImg: 'assets/img/social/image-sponsor-es.jpg',
  },
  geolocation: {
    highAccuracy: false,
    maxAccuracy: 100000, // 100km
  },
  features: {
    verificationDialogNewFlow: true,
    groups: {
      filterGames: true,
    },
    booths: {
      enabled: true,
      distanceAlert: {near: 49000, far: 300000}, // metters
    },
    money: {
      annualSummary: true,
    },
    // Subscribe specific days and by jackpot
    subscribe: {
      enable: true,
      maxDaysSelectorBreakpoint: 'lg',
    },
    keepPrizeInGroups: true,
    games: {
      lottery: {
        // Enable button change Booth on Delivery
        allowChangeRandomBoothOnDelivery: true,
      },
    },
  },
  maintenance: {redirectUrl: 'maintenance-es.html'},
};
